import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Navbar from "./Comp/MajorNavbar/MajorNavbar";
import Footer from "./Comp/Footer/Footer";

import Home from "./Pages/Home/Home";
import Policy from "./Pages/Policy/Policy";
import About from "./Pages/AboutUs/AboutUs";
import Contact from "./Pages/Contact/Contact";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/SignUp/SignUp";
// import Dashboard from "./Pages/Dashboard/Dashboard";
// import Profile from "./Pages/Profile/Profile";
// import Logout from "./Pages/Logout/Logout";
import NotFound from "./Pages/NotFound/NotFound";
import { isAuthenticated } from "./Utils/Auth/auth";

import "./reset.scss";
import "./App.scss";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const checkAuth = async () => {
    const loggedIn = await isAuthenticated();
    setIsLoggedIn(loggedIn);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (isLoggedIn === null)
    return (
      <div className="App">
        <Router>
          <div className="app-container">
            <Navbar isLoggedIn={isLoggedIn} />
            <div className="app-content" />
            <Footer isLoggedIn={isLoggedIn} />
          </div>
        </Router>
      </div>
    );

  return (
    <div className="App">
      <Router>
        <div className="app-container">
          <Navbar isLoggedIn={isLoggedIn} />
          <div className="app-content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/policy" element={<Policy />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/contact" element={<Contact />} />
              {isLoggedIn ? (
                <>
                  <Route
                    exact
                    path="/login"
                    element={<Navigate to="/dashboard" />}
                  />
                  <Route
                    exact
                    path="/signup"
                    element={<Navigate to="/dashboard" />}
                  />
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/login"
                    element={<Login checkAuth={checkAuth} />}
                  />
                  <Route exact path="/signup" element={<SignUp />} />
                  <Route
                    exact
                    path="/dashboard"
                    element={<Navigate to="/login" />}
                  />
                </>
              )}
              {/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
              {/* <Route exact path="/profile" element={<Profile />} /> */}
              {/* <Route exact path="/logout" element={<Logout />} /> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer isLoggedIn={isLoggedIn} />
        </div>
      </Router>
    </div>
  );
};

export default App;
