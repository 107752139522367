import axios from "axios";

// Example usage:
// getPhonemes("cat").then((phonemes) => {
//   console.log(`Phonemes for 'cat':`, phonemes);
// });
export async function getPhonemes(word) {
  try {
    const response = await axios.get(`/api/phonemes?word=${word}`);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching phonemes:", error);
    return null;
  }
}

// Example usage:
//  addWord('bleckedish', [['B', 'L', 'EH', 'K', 'IY', 'SH']]);
export async function addWord(word, phonemes) {
  try {
    const response = await axios.post("/api/add_word", {
      word: word,
      phonemes: phonemes,
    });
    console.log(response.data);
  } catch (error) {
    console.error("Error adding word:", error);
  }
}

const cmuToIpaMap = {
  // Vowels
  AA: "ɑ", // father
  AE: "æ", // cat
  AH: "ʌ", // cut
  AO: "ɔ", // dog
  AW: "aʊ", // cow
  AY: "aɪ", // ride
  EH: "ɛ", // bed
  ER: "ɝ", // bird (stressed)
  EY: "eɪ", // bait
  IH: "ɪ", // bit
  IY: "i", // beet
  OW: "oʊ", // boat
  OY: "ɔɪ", // toy
  UH: "ʊ", // book
  UW: "u", // food

  // R-controlled vowels
  AIR: "ɛɹ", // as in air, pair
  EHR: "ɛɹ", // as in ere, hair
  OR: "ɔɹ", // as in or, more
  AR: "ɑɹ", // as in car, far
  ER: "ɝ", // stressed R-colored vowel, bird
  UR: "ʊɹ", // as in fur, sure

  // Consonants
  B: "b", // big
  CH: "tʃ", // cheese
  D: "d", // dog
  DH: "ð", // this
  F: "f", // fish
  G: "ɡ", // go
  HH: "h", // hat
  JH: "dʒ", // judge
  K: "k", // cat
  L: "l", // leaf
  M: "m", // map
  N: "n", // nap
  NG: "ŋ", // sing
  P: "p", // pen
  R: "ɹ", // red (ɹ for the consonant "r")
  S: "s", // sit
  SH: "ʃ", // ship
  T: "t", // top
  TH: "θ", // thin
  V: "v", // van
  W: "w", // win
  Y: "j", // yes
  Z: "z", // zoo
  ZH: "ʒ", // measure

  // Optional vowel variants with stress markers (can be ignored)
  AA0: "ɑ",
  AA1: "ɑ",
  AA2: "ɑ",
  AE0: "æ",
  AE1: "æ",
  AE2: "æ",
  AH0: "ə",
  AH1: "ʌ",
  AH2: "ʌ",
  AO0: "ɔ",
  AO1: "ɔ",
  AO2: "ɔ",
  AW0: "aʊ",
  AW1: "aʊ",
  AW2: "aʊ",
  AY0: "aɪ",
  AY1: "aɪ",
  AY2: "aɪ",
  EH0: "ə",
  EH1: "ɛ",
  EH2: "ɛ",
  ER0: "ɚ",
  ER1: "ɝ",
  ER2: "ɝ",
  EY0: "ə",
  EY1: "eɪ",
  EY2: "eɪ",
  IH0: "ɪ",
  IH1: "ɪ",
  IH2: "ɪ",
  IY0: "i",
  IY1: "i",
  IY2: "i",
  OW0: "ə",
  OW1: "oʊ",
  OW2: "oʊ",
  OY0: "ɔɪ",
  OY1: "ɔɪ",
  OY2: "ɔɪ",
  UH0: "ə",
  UH1: "ʊ",
  UH2: "ʊ",
  UW0: "u",
  UW1: "u",
  UW2: "u",
};

// Example usage:
// const cmuPhonemes = ['K', 'AE1', 'T'];
// const ipaPhonemes = convertCmuToIpa(cmuPhonemes);
// console.log(`IPA: ${ipaPhonemes.join(' ')}`);  // Output: IPA: k æ t
export function convertCmuToIpa(cmuPhonemes) {
  return cmuPhonemes.map((phoneme) => {
    // Remove the stress markers (0, 1, 2) from vowel phonemes
    const basePhoneme = phoneme.replace(/[0-2]/g, "");
    return cmuToIpaMap[basePhoneme] || basePhoneme; // Return IPA if mapped, otherwise return original
  });
}
